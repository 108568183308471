import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('othersController', othersController);

  othersController.$inject = ['$rootScope', '$scope', '$state', 'configFactory', 'fertilizerFactory'];

  function othersController($rootScope, $scope, $state, configFactory, fertilizerFactory) {
    var vm = this;
    var backup;
    var fertilizer_updated;
    vm.activeList;
    vm.getType;
    vm.UNITS = UNITS;

    backup = angular.copy(vm.others);
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.toogle = toogle;
    vm.timerSuffixText = `' "`;
    vm.drainUnits = { 0: 'ml', 1: 'L' };

    vm.selected = 1;
    vm.activeSect = _.range(1, 9);
    vm.activeProg = _.range(1, 5);
    vm.changedFert = changedFert;
    vm.instantFlow = { 0: 'No - 650,00 m3/h - l/h', 1: 'Si - 6500,0 m3/h - l/h' };
    vm.formatFlow = { 0: '00000 m3', 1: '0000.0 m3', 2: '000.00 m3' };
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        setFormWatchers();
        loadData();
      });
    }

    function loadData() {
      if (vm.currentUnit.type === vm.UNITS.A_2500 && vm.currentUnit.inoptions && !vm.currentUnit.inoptions.plus) {
        vm.fertilizerUnits = { 0: 'hh:mm', 1: 'L' };
      } else if (vm.currentUnit.type === vm.UNITS.A_5500) {
        vm.fertilizerUnits = { 0: 'hh:mm', 1: 'mm\'ss"', 2: 'L', 3: 'L/Ha' };
      } else {
        vm.fertilizerUnits = { 0: 'hh:mm', 1: 'L', 2: 'L/Ha', 3: 'mm\'ss"' };
      }

      fertilizer_updated = false;
      // Since it is a form, we do not want to refresh data every refresh, just in first load.
      if (!vm.others) {
        vm.others = angular.copy(vm.currentUnit.installer);
      }
      if (!vm.fertilizer) {
        vm.fertilizer = angular.copy(vm.currentUnit.fertilizer);
      }
    }

    function toogle(id) {
      vm.selected = id;
    }

    function changedFert() {
      fertilizer_updated = true;
    }

    function save() {
      resetFormState();
      vm.others.unittype = vm.currentUnit.type;
      vm.others.progtype = vm.currentUnit.type;
      vm.loading = true;

      configFactory.updateOthers(vm.currentUnit.id, vm.others).then(
        (result) => {
          if (result) {
            vm.others = result;
            $scope.$emit('refresh');
          }
          backup = angular.copy(vm.others);

          if (fertilizer_updated) {
            saveFertilizer();
          } else {
            vm.loading = false;
          }
        },
        () => {}
      );
    }

    function saveFertilizer() {
      vm.fertilizer.unittype = vm.currentUnit.type;
      fertilizerFactory.update(vm.currentUnit.id, vm.fertilizer).then(
        (result) => {
          if (vm.currentUnit.type === vm.UNITS.A_2500) vm.fertilizer = result.fertilizer;
          else vm.fertilizer = result;
          vm.loading = false;
          // Need to reload currentUnit because form is based on current unit. If not, changes are only applied on general refresh.
          const deviceId = $state.params.unitId;
          vm.devicesFacade.loadICMDeviceById(deviceId, { add: ['format'] });
        },
        () => {}
      );
    }

    function resetFormState() {
      if (vm.currentUnit.type === vm.UNITS.A_4000) {
        return vm.forma.$setPristine();
      }
      if (vm.currentUnit.type === vm.UNITS.A_2500) {
        return vm.formb.$setPristine();
      }
      if (vm.currentUnit.type === vm.UNITS.A_BIT) {
        return vm.formc.$setPristine();
      }
      if (vm.currentUnit.type === vm.UNITS.A_5500) {
        return vm.formd.$setPristine();
      }
      if (vm.currentUnit.type === vm.UNITS.A_7000) {
        return vm.forme.$setPristine();
      }
    }

    function cancel_edition() {
      vm.others = angular.copy(backup);
      resetFormState();

      vm.events = angular.copy(backup);
    }

    function setFormWatchers() {
      $scope.$watch(
        () => vm.formb,
        () => {
          if (vm.currentUnit.type === vm.UNITS.A_2500) {
            $scope.$emit('formToUnitUpdated', vm.formb);
          }
        }
      );

      $scope.$watch(
        () => vm.formc,
        () => {
          if (vm.currentUnit.type === vm.UNITS.A_BIT) {
            $scope.$emit('formToUnitUpdated', vm.formc);
          }
        }
      );

      $scope.$watch(
        () => vm.forma,
        () => {
          if (vm.currentUnit.type === vm.UNITS.A_4000) {
            $scope.$emit('formToUnitUpdated', vm.forma);
          }
        }
      );
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $scope.$on('formFromUnitCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
