import moment from 'moment/moment';
import { A4000_DEFAULT_CONFIG } from '../utils/device-config';
import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';
import { Subject, take, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)
    .directive('minutes', minutesDirective)
    .controller('programsEditController', programsEditController);

  programsEditController.$inject = [
    '$log',
    '$scope',
    '$rootScope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'UserData',
    '$filter',
    '$mdSelect',
    'resFactory',
    '$timeout',
    'sensorsFactory',
  ];

  function programsEditController(
    $log,
    $scope,
    $rootScope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    UserData,
    $filter,
    $mdSelect,
    resFactory,
    $timeout,
    sensorsFactory
  ) {
    var vm = this;
    var fertilizers_in_unit;
    vm.activeList;
    vm.form;
    vm.fertNum;
    vm.autofocusElement;
    vm.irrigationMeasureUnit;
    vm.fertilizerMeasureUnit;
    vm.seqProgram;
    vm.startActivationHour;
    vm.endActivationHour;

    vm.checkdirty = checkdirty;
    vm.deleteProgram = deleteProgram;
    vm.prepareSectorsToUpdate = prepareSectorsToUpdate;
    vm.resetProgramType = resetProgramType;
    vm.deleteSubprogram = deleteSubprogram;
    vm.addSubprogram = addSubprogram;
    vm.cancel = cancel_edition;
    vm.save = save;
    vm.selectPrograms = _.range(1, 36);
    vm.sectors = _.range(1, 100);
    vm.removeSelectedSector = removeSelectedSector;
    vm.checkEditConditioner = checkEditConditioner;
    vm.checkAllConditioners = checkAllConditioners;
    vm.getSensorFormat = getSensorFormat;
    vm.changedType = changedType;
    vm.updateActivationTime = updateActivationTime;

    vm.showIrrigationUnits = showIrrigationUnits;
    vm.hideIrrigationUnits = hideIrrigationUnits;
    vm.showFertUnits = showFertUnits;
    vm.hideFertUnits = hideFertUnits;
    vm.openSubProgramWindow = openSubProgramWindow;
    vm.cancelModule = cancelModule;
    vm.saveModule = saveModule;
    vm.isSectorSelected = isSectorSelected;
    vm.setIrrigationMeasureUnit = setIrrigationMeasureUnit;
    vm.setFertilizerMeasureUnit = setFertilizerMeasureUnit;
    vm.updateRangeDates = updateRangeDates;
    vm.dataGridRowClick = dataGridRowClick;
    vm.toggleActivePeriod = toggleActivePeriod;

    vm.unitValues = A4000_DEFAULT_CONFIG.measurementUnits.irrigation;
    vm.unitFertValues = A4000_DEFAULT_CONFIG.measurementUnits.fertilization;

    vm.currentUnit = $state.params.unit;

    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();

    initSubscriptions();

    function initSubscriptions() {
      const programId = $state.params.id;
      vm.devicesFacade.legacyUnit$.pipe(take(1)).subscribe((currentUnit) => {
        vm.currentUnit = currentUnit;
        vm.programsFacade.getA4000Program(vm.currentUnit.id, programId);
      });
      vm.programsFacade.programById$.pipe(takeUntil(vm.destroy$)).subscribe((res) => {
        vm.editProgram = res;
        vm.seqProgram = vm.editProgram.start - 99 * 60;
        activate();
      });
    }

    function activate() {
      vm.currentState = $state.includes;

      if ($state.$current.parent.name === 'programs') {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.show();
      }

      if (vm.currentUnit.type === UNITS.A_4000) {
        vm.irrigationFlowUnit = A4000_DEFAULT_CONFIG.measurementUnits.irrigation.find(
          (unit) => unit.deviceMeasurementUnitKey === vm.currentUnit.flow.irrigationUnit
        );
      }

      //    if(!vm.editProgram.backup){
      backup();
      //    }else{

      //    }
      vm.fertNum = fertilizers_in_unit = vm.currentUnit.fertilizer.fertNum;
      parseStartEndActivationDay();

      vm.proportional = vm.currentUnit.fertilizer.fertType === 1 && vm.currentUnit.fertilizer.prop; //Paralel prop
      progFactory.filterSubPrograms(vm.editProgram);

      prepareParsedSectors();
      checkStartType();
      checkSubprograms();
      if (checkAllConditioners()) {
        vm.anyConditionerAdded = vm.editProgram.conditioners.some((conditioner) => conditioner.type !== 0);
        vm.showConditioners = true;
        /*resFactory.cond(vm.currentUnit.type).then(function(response){
                vm.conditionerList = response;
               })*/
        vm.conditionerList = resFactory.cond(vm.currentUnit.type);
        vm.integrationUnits = resFactory.integrationUnits(vm.currentUnit.type);
        /*resFactory.integrationUnits(vm.currentUnit.type).then(function(response){
                vm.integrationUnits = response;   
               })*/
      }

      if (vm.proportional) {
        parsePropFertilizers();
      }
      vm.gridData = vm.editProgram.subprograms.map((sub) => ({
        ...sub,
        pkId: sub.pk.id,
        rowItemDisabled: !sub.parsedSectorsTemp.find((sec) => sec !== 0),
      }));
      vm.irrigationUnits = A4000_DEFAULT_CONFIG.measurementUnits.irrigation;

      vm.gridColumnsDef = [
        {
          headerName: $filter('translate')('general.subp2'),
          field: 'pkId',
          cellTemplate: (value) => getCellTemplate(value, 'name'),
        },
        {
          headerName: $filter('translate')('general.sectors'),
          field: '',
          cellTemplate: (value) => getCellTemplate(value, 'sectors'),
        },
        {
          headerName: $filter('translate')('programs.edit.irrig'),
          field: 'activeSectors',
          cellTemplate: (value) => getCellTemplate(value, 'irrigation'),
        },
        {
          headerName: 'F1',
          field: 'fertilizer1',
          cellTemplate: ({ fertilizer1, fertUnit }) => getFertilizerCellTemplate(fertilizer1, fertUnit),
        },
        {
          headerName: 'F2',
          field: 'fertilizer2',
          cellTemplate: ({ fertilizer2, fertUnit }) => getFertilizerCellTemplate(fertilizer2, fertUnit),
        },
        {
          headerName: 'F3',
          field: 'fertilizer3',
          cellTemplate: ({ fertilizer3, fertUnit }) => getFertilizerCellTemplate(fertilizer3, fertUnit),
        },
        {
          headerName: 'F4',
          field: 'fertilizer4',
          cellTemplate: ({ fertilizer4, fertUnit }) => getFertilizerCellTemplate(fertilizer4, fertUnit),
        },
        {
          headerName: 'F5',
          field: 'fertilizer5',
          cellTemplate: ({ fertilizer5, fertUnit }) => getFertilizerCellTemplate(fertilizer5, fertUnit),
        },
        {
          headerName: $filter('translate')('fert.state'),
          field: 'state',
          cellTemplate: (val) => getStateCellTemplate(val),
        },
      ];
    }

    function getCellTemplate(value, key) {
      const { sector1, sector2, sector3, sector4, sector5, sector6, sector7, sector8, sector9, sector10 } = value;
      const activeSectors = [
        sector1,
        sector2,
        sector3,
        sector4,
        sector5,
        sector6,
        sector7,
        sector8,
        sector9,
        sector10,
      ].filter((sec) => sec > 0);
      if (key === 'sectors') {
        return activeSectors.length
          ? `<div style="display: flex; flex-direction: column; align-items: start">
        ${activeSectors.map((sec) => `<vegga-badge>S${sec}</vegga-badge>`)}
        </div>`.replaceAll(',', '')
          : `<vegga-text>-</vegga-text>`.replaceAll(',', '');
      }

      if (key === 'irrigation') {
        const { value: unitValue, unit } = value;
        const measurementUnit = vm.irrigationUnits.find((u) => u.deviceMeasurementUnitKey === unit);

        return activeSectors.length && !!measurementUnit
          ? `<vegga-text> ${parseMeasurementUnitValue(unitValue, measurementUnit.deviceMeasurementUnitKey)} ${
              measurementUnit.measurementUnit.symbol
            }</vegga-text>`
          : `<vegga-text>-</vegga-text>`;
      }

      if (key === 'name') {
        return `<vegga-text>SP${value.pkId}</vegga-text>`;
      }
    }

    function getStateCellTemplate(value) {
      if (vm.editProgram.irrigation && vm.editProgram.xSubprogramCourse + 1 === +value.pkId) {
        return `<vegga-badge class="info">${$filter('translate')('programs.irrigating')}</vegga-badge>`;
      }

      if (!value.parsedSectorsTemp.length) {
        return `<vegga-text>-</vegga-text>`;
      }
      return `<vegga-badge>${$filter('translate')('programs.noirrigating')}</vegga-badge>`;
    }

    function getFertilizerCellTemplate(fertValue, fertUnit) {
      const hasProportionalFertilization = vm.currentUnit.fertilizer.prop;

      if (hasProportionalFertilization) {
        const [fertParsedValue, irrigParsedValue] = parseValueToIrrigAndFertValue(fertValue);
        return `<vegga-text>${irrigParsedValue}/${fertParsedValue}</vegga-text>`;
      }

      const unit = vm.unitFertValues.find((u) => u.deviceMeasurementUnitKey === fertUnit);

      return unit
        ? `<vegga-text>${parseMeasurementUnitValue(fertValue, unit.deviceMeasurementUnitKey)} ${
            unit.measurementUnit.symbol
          }</vegga-text>`
        : '-';
    }

    function dataGridRowClick($event) {
      const sub = $event.detail;

      !sub.operative ? vm.addSubprogram($event.detail.gridPos) : vm.openSubProgramWindow($event.detail.gridPos);
    }

    function parseMeasurementUnitValue(value, deviceMeasurementUnitKey) {
      switch (deviceMeasurementUnitKey) {
        case 0: {
          const m = value % 60;
          const h = (value - m) / 60;
          return (h < 10 ? '0' : '') + h.toString() + ':' + (m < 10 ? '0' : '') + m.toString();
        }
        case 1: {
          const seconds = value % 60;
          const minutes = (value - seconds) / 60;
          return (minutes < 10 ? '0' : '') + minutes.toString() + ':' + (seconds < 10 ? '0' : '') + seconds.toString();
        }
        default:
          return value;
      }
    }

    function parseStartEndActivationDay() {
      const startDay = vm.editProgram.startActivationDay;
      const startMonth = vm.editProgram.startActivationMonth;
      const endDay = vm.editProgram.endActivationDay;
      const endMonth = vm.editProgram.endActivationMonth;

      [vm.from, vm.to] = [`${startDay}-${startMonth}`, `${endDay}-${endMonth}`];
      vm.isActivePeriodEnabled = startDay && startMonth && endDay && endMonth;
    }

    function toggleActivePeriod($event) {
      vm.isActivePeriodEnabled = $event.detail.checked;
      vm.formProgram.$setDirty();
    }

    function updateRangeDates($event) {
      const range = $event.detail;
      [vm.from, vm.to] = range;
      vm.editProgram.startActivationDay = +range[0].split('-')[0];
      vm.editProgram.startActivationMonth = +range[0].split('-')[1];

      vm.editProgram.endActivationDay = +range[1].split('-')[0];
      vm.editProgram.endActivationMonth = +range[1].split('-')[1];

      vm.form.$setDirty();
    }

    function updateActivationTime($event, key) {
      const time = $event.detail;

      if (key === 'start') {
        vm.editProgram.startActivationHour = time;
      }

      if (key === 'end') {
        vm.editProgram.endActivationHour = time;
      }

      vm.form.$setDirty();
    }

    function setIrrigationMeasureUnit($event) {
      vm.irrigationMeasureUnit = vm.unitValues.find(
        (unit) => unit.deviceMeasurementUnitKey === $event.detail.value
      ).measurementUnit;
    }

    function setFertilizerMeasureUnit($event) {
      vm.fertilizerMeasureUnit = vm.unitFertValues.find(
        (unit) => unit.deviceMeasurementUnitKey === $event.detail.value
      ).measurementUnit;
    }

    function checkEditConditioner(cond) {
      if (cond.sensor !== 0) {
        switch (cond.type) {
          case 1:
          case 2:
          case 3:
            return true;
          //cond.int = true;
          case 7:
          case 8:
          case 9:
            cond.fact = true;
            cond.int = true;
            return true;
          case 10:
          case 11:
            cond.fact = true;
            return true;
          default:
            return false;
        }
      } else {
        return false;
      }
    }

    function checkAllConditioners() {
      var sensors = _.filter(vm.editProgram.conditioners, (item) => {
        return item.sensor !== 0;
      });
      return sensors.length > 0;
    }

    function getSensorFormat(cond) {
      var sensorId = cond.sensor;
      if (sensorId === 41) {
        var formatId = 99;
        if (cond.int && (formatId === 1 || formatId === 2 || formatId === 99)) {
          integrationFormats(formatId, cond);
        } else {
          cond.format = vm.currentUnit.formats[formatId - 1];
          cond.loaded = true;
        }
      } else {
        var params = { page: sensorId, limit: 1 };
        vm.sensor = {};
        sensorsFactory.analogsPage(vm.currentUnit.id, params).then(function (data) {
          vm.paginator = data.plain();
          angular.copy(vm.paginator.content[0], vm.sensor);
          var formatId = vm.sensor.formatId;
          if (cond.int && (formatId === 1 || formatId === 2 || formatId === 99)) {
            integrationFormats(formatId, cond);
          } else {
            cond.format = vm.currentUnit.formats[formatId - 1];
            cond.loaded = true;
          }
        });
      }
    }

    function integrationFormats(id, cond) {
      if (vm.integrationUnits === undefined) {
        return resFactory.integrationUnits(vm.currentUnit.type).then(function (response) {
          vm.integrationUnits = response;
          cond.format = vm.integrationUnits[id];
          cond.loaded = true;
        });
      } else {
        cond.format = vm.integrationUnits[id];
        cond.loaded = true;
      }
    }

    function hideIrrigationUnits() {
      //        $timeout(function(){ vm.showIrrigation = false;},100)
    }

    function showIrrigationUnits() {
      vm.showIrrigation = true;
      vm.showFert = false;
      //        vm.autofocusElement = event.target.id;
    }

    function hideFertUnits() {
      //       $timeout(function(){ vm.showFert = false;},100)
    }

    function showFertUnits() {
      vm.showFert = true;
      vm.showIrrigation = false;
      //        vm.autofocusElement = event.target.id;
    }

    function prepareParsedSectors() {
      _.forEach(vm.editProgram.subprograms, (item) => {
        item.parsedSectorsTemp = [];
        angular.copy($filter('operational')(item.parsedSectors), item.parsedSectorsTemp);
      });
    }

    function checkdirty(form) {
      if (vm.editProgram.backup) {
        form.$setDirty();
      }
    }

    function backup() {
      vm.program_backup = {};
      angular.copy(vm.editProgram, vm.program_backup);
      vm.editProgram.backup = true;
    }

    function prepareSectorsToUpdate(subProgram) {
      if (
        subProgram.selectedSector &&
        Array.isArray(subProgram.selectedSector) &&
        subProgram.selectedSector.length >= 0
      ) {
        subProgram.parsedSectorsTemp = [];
        subProgram.selectedSector.forEach((sub) => {
          if (sub > 0) {
            let limit = 10;
            if (vm.currentUnit.inoptions && vm.currentUnit.inoptions.ph) {
              limit = 8;
            }
            if (subProgram.parsedSectorsTemp.length < limit) {
              subProgram.parsedSectorsTemp.push(sub);
              _.forEach(subProgram.parsedSectors, (item, key) => {
                if (subProgram.parsedSectorsTemp[key] !== undefined) {
                  subProgram['sector' + (key + 1)] = Number(subProgram.parsedSectorsTemp[key]);
                } else {
                  subProgram['sector' + (key + 1)] = 0;
                }
              });
            }
          }
        });

        subProgram.selectedSector = [];
      }
    }

    function prepareFertilizers() {
      _.forEach(vm.editProgram.subprograms, (item) => {
        if (item.operative) {
          _.forEach(item.ferilizerValues, (value, key) => {
            if (value !== null) {
              if (vm.proportional) {
                item['fertilizer' + (key + 1)] = (item.irrigationProp[key] << 8) + item.fertilizationProp[key];
              } else {
                item['fertilizer' + (key + 1)] = value;
              }
            } else {
              item['fertilizer' + (key + 1)] = 0;
            }
          });
        }
      });
    }

    function parseValueToIrrigAndFertValue(fertValue) {
      if (!fertValue) return [0, 0];
      const irrigValue = fertValue >> 8;
      const parsedFertValue = fertValue & 0x00ff;

      return [parsedFertValue, irrigValue];
    }

    function parsePropFertilizers() {
      _.forEach(vm.editProgram.subprograms, (item) => {
        if (item.operative) {
          var fertilizers = _.pickBy(item, function (value, key) {
            return _.startsWith(key, 'fertilizer');
          });
          var values = _.values(fertilizers);
          item.irrigationProp = [];
          item.fertilizationProp = [];
          _.forEach(values, (v, k) => {
            const [fertValue, irrigValue] = parseValueToIrrigAndFertValue(values[k]);
            item.irrigationProp[k] = irrigValue;
            item.fertilizationProp[k] = fertValue; //(values[k]  - item.irrigationProp[k]);
          });
        }
      });
    }

    function removeSelectedSector(index, sub) {
      sub.parsedSectorsTemp.splice(index, 1);
      _.forEach(sub.parsedSectors, (item, key) => {
        if (sub.parsedSectorsTemp[key] !== undefined) {
          sub['sector' + (key + 1)] = Number(sub.parsedSectorsTemp[key]);
        } else {
          sub['sector' + (key + 1)] = 0;
        }
      });
    }

    function parseActivePeriod() {
      if (vm.isActivePeriodEnabled) return;
      vm.editProgram.startActivationDay = 0;
      vm.editProgram.startActivationMonth = 0;
      vm.editProgram.endActivationDay = 0;
      vm.editProgram.endActivationMonth = 0;
    }

    function save(form) {
      parseActivePeriod();
      vm.form.$setPristine();
      if (form) {
        vm.editProgram.progtype = vm.currentUnit.type;
        prepareFertilizers();

        if (vm.editProgram.new) {
          _.forEach(vm.editProgram.subprograms, (sub) => {
            sub.pk.deviceId = vm.editProgram.pk.deviceId;
            sub.pk.programId = vm.editProgram.pk.id;
          });
        }
        if (vm.editProgram.sequential) {
          vm.editProgram.sequential = 1;
        }
        progFactory.update(vm.editProgram).then(() => $rootScope.$broadcast('updateEdit', { message: 'refreshing' }));
      }
    }

    function cancel_edition() {
      angular.copy(vm.program_backup, vm.editProgram);
      //$state.go('programs.detail',{unit:vm.currentUnit,program:vm.program_backup});
    }

    function checkStartType() {
      if (vm.editProgram.preIrrigation === null) {
        vm.editProgram.preIrrigation = 0;
      }
      if (vm.editProgram.sequential) {
        vm.type = 'seq';
      } else {
        vm.type = 'hour';
      }
    }

    function checkSubprograms() {
      var operative_subprograms = _.map(vm.editProgram.subprograms, function (o) {
        var item = $filter('operational')(o.parsedSectors);
        o.operative = item.length > 0;
        return item.length > 0;
      });
      vm.editProgram.operativeSubprograms = _.filter(operative_subprograms, (o) => {
        return o;
      }).length;
    }

    function deleteProgram() {
      var model;

      progFactory.getModel(vm.currentUnit.type).then((item) => {
        model = item;
        prepareModel(model);
        progFactory.update(model).then(
          () => {
            $rootScope.$broadcast('updateEdit', { message: 'refreshing' });
            $state.go('programs', { unit: vm.currentUnit, program: null });
          },
          () => {
            //TODO ERROR ALERT
          }
        );
      });
    }

    function deleteSubprogram() {
      const sub = vm.editProgram.subprograms[vm.programSelectedIndex];

      sub.operative = false;
      sub.selectedSector = [];
      var size = sub.parsedSectors.length; //Number of sectors per sub-program
      sub.value = 0;
      sub.unit = 0;
      sub.fertUnit = 0;
      sub.phReference = 0;
      var key = 1;
      _.times(size, function () {
        if (sub['sector' + key] !== undefined) {
          sub['sector' + key] = 0;
        }
        if (sub['fertilizer' + key] !== undefined) {
          sub['fertilizer' + key] = 0;
        }
        key++;
      });

      document.getElementById('vegga-modal-programs').hide();
      vm.programSelectedIndex = null;
      vm.subProgramSelected = null;
      vm.form.$setDirty();
    }

    function addSubprogram(index) {
      const sub = vm.editProgram.subprograms[index];
      sub.operative = true;
      sub.selectedSector = [];
      var size = 10; //Number of sectors per sub-program
      sub.value = 0;
      sub.unit = sub.flow;
      sub.fertUnit = 0;
      sub.phReference = 0;
      sub.parsedSectorsTemp = [];
      sub.parsedSectors = _.times(size, _.constant(0));
      sub.ferilizerValues = _.times(fertilizers_in_unit, _.constant(0)); //Number of fertilizers per sub-program
      var key = 1;
      _.times(size, function () {
        if (sub['sector' + key] !== undefined) {
          sub['sector' + key] = 0;
        }
        if (sub['fertilizer' + key] !== undefined) {
          sub['fertilizer' + key] = 0;
        }
        key++;
      });

      vm.openSubProgramWindow(index);
    }

    function prepareModel(model) {
      var id = vm.editProgram.pk.id;
      model.pk.id = id;
      model.pk.deviceId = vm.currentUnit.id;
      model.name = 'Programa ' + id;
      _.forEach(model.subprograms, (item) => {
        item.pk.programId = id;
        item.pk.deviceId = vm.currentUnit.id;
      });
      _.forEach(model.conditioners, (item) => {
        item.pk.programId = id;
        item.pk.deviceId = vm.currentUnit.id;
      });
    }

    function resetProgramType(value) {
      var minutes1 = 99 * 60;
      var minutes2 = Number(value);
      vm.editProgram.start = minutes1 + minutes2;

      vm.seqProgram = value;
    }

    function changedType() {
      vm.editProgram.start = 0;
    }

    function openSubProgramWindow(index) {
      if (vm.editProgram.subprograms[index]) {
        vm.programSelectedIndex = index;
        vm.subProgramSelected = angular.copy(vm.editProgram.subprograms[index]);
        vm.subProgramSelected.selectedSector = vm.editProgram.subprograms[index].parsedSectorsTemp;
        vm.irrigationMeasureUnit = vm.unitValues.find(
          (unit) => unit.deviceMeasurementUnitKey === vm.subProgramSelected.unit
        ).measurementUnit;

        vm.fertilizerMeasureUnit = vm.unitFertValues.find(
          (unit) => unit.deviceMeasurementUnitKey === vm.subProgramSelected.fertUnit
        ).measurementUnit;

        document.getElementById('vegga-modal-programs').show();
      }
    }

    function cancelModule() {
      vm.prepareSectorsToUpdate(vm.subProgramSelected);
      vm.subProgramSelected = null;
      vm.programSelectedIndex = null;
      document.getElementById('vegga-modal-programs').hide();
    }

    function saveModule() {
      vm.prepareSectorsToUpdate(vm.subProgramSelected);

      vm.subProgramSelected.ferilizerValues.forEach((item, i) => {
        vm.subProgramSelected['fertilizer' + (i + 1)] = item;
      });

      vm.editProgram.subprograms[vm.programSelectedIndex] = angular.copy(vm.subProgramSelected);
      vm.gridData = vm.editProgram.subprograms.map((sub) => ({
        ...sub,
        pkId: sub.pk.id,
        rowItemDisabled: !sub.parsedSectorsTemp.find((sec) => sec !== 0),
      }));

      document.getElementById('vegga-modal-programs').hide();
      vm.subProgramSelected = null;
      vm.programSelectedIndex = null;
      vm.form.$dirty = true;
    }

    function isSectorSelected(sector) {
      const sectorTemp = vm.subProgramSelected.parsedSectorsTemp.find((s) => s === sector);
      return sectorTemp ? true : false;
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', () => {
      save(vm.form);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', () => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
      vm.programsFacade.clearProgramResponse();
    });
  }

  function minutesDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        sec: '=',
      },
      link: function (scope, element, attr, ngModel) {
        ngModel.$parsers.push(fromUser);
        ngModel.$formatters.push(toUser);

        function fromUser(input) {
          var duration = moment(input).format('HH:mm');
          if (scope.sec === true) {
            return moment.duration(duration).asMinutes() * 60;
          } else {
            return moment.duration(duration).asMinutes();
          }
        }

        function toUser(input) {
          if (scope.sec === true) {
            input = input / 60;
          }
          if (_.isNumber(input)) {
            if (!(input >= 24 * 60 || input < 0)) {
              var h = (input / 60) | 0,
                m = input % 60 | 0;
              return moment(new Date()).hours(h).minutes(m).seconds(0).milliseconds(0)._d;
            }
          }
        }
      },
    };
  }
})();
